import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from './redux/actions'
import { bindActionCreators } from 'redux'
import asyncComponent from './AsyncComponent'
import { withRouter, Switch } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Route } from 'react-router';
import AppLayout from './app/app';
import { Layout } from './components/Layout';
// import { Home } from './components/Home';
import { FetchData } from './components/templater/FetchData';
import { Counter } from './components/templater/Counter';
import { UsersRouter } from './components/users/UsersRouter';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';


import './custom.css'

const AskMe = asyncComponent(() => import('./app/chat/askMe'))
const PageFrame = asyncComponent(() => import('./app/iframe/pageFrame'))
const ChatSetting = asyncComponent(() => import('./app/setting/chatSetting'))
const UsersList = asyncComponent(() => import('./app/users/usersList'))
const HistoryList = asyncComponent(() => import('./app/general/historyList'))
const SensitiveWord = asyncComponent(() => import('./app/general/sensitiveWord'))
const ReportWrite = asyncComponent(() => import('./app/chat/reportWrite'))

class App extends Component {
	static displayName = App.name;

  state = {
    modeType: 'app',
      pathNameArr: ['/chat-official', '/chat-settings', '/profile', '/pricing', '/billing', '/login', '/', '/system/user', '/general/history', '/sensitive-word', '/report-write'],
  }

  componentDidMount() {
    // 判断是否是app模式
    this.listenRouter(this.props.location, this.props.action);
    // 路由监听
    this.unlisten = this.props.history.listen((location, action)=>{ this.listenRouter(location, action) })
  }
  componentWillUnmount() {
    this.unlisten();
  }

  listenRouter(location, action) {
    // console.log("路由监听：", this.state.pathNameArr.includes(location.pathname));
    if (this.state.pathNameArr.includes(location.pathname)) {
      this.setState({
        modeType: 'app',
      })
    } else {
      this.setState({
        modeType: 'manage',
      })
    }
  }

	render() {
    // console.log(this.state.modeType)
		return (
      <>
        {this.state.modeType === 'app' ? 
          <AppLayout {...this.props}>
            <Switch>
              {/* <Route path="/" render={(props) => <AskMe official={true} {...this.props} />} /> */}
              <Route path="/chat-official" render={(props) => <AskMe official={true} {...this.props} />} />
              <Route path="/profile" render={(props) => <PageFrame {...this.props} href="https://www.novagpt.ai/account/my-account" />} />
              <Route path="/pricing" render={(props) => <PageFrame {...this.props} href="https://www.novagpt.ai/pricing-plans" />} />
              <Route path="/billing" render={(props) => <PageFrame {...this.props} href="https://www.novagpt.ai/account/my-subscriptions" />} />
              <Route path="/chat-settings" render={(props) => <ChatSetting {...this.props} />} />
              <Route path="/report-write" render={(props) => <ReportWrite official={true} {...this.props} />} />
              <Route path='/system/user'  render={(props) => <UsersList {...this.props} />} />
              <Route path='/general/history' render={(props) => <HistoryList {...this.props} />} />
              <Route path='/sensitive-word' render={(props) => <SensitiveWord {...this.props} />} />
            </Switch>
          </AppLayout>:
          <Layout>
            {/* <Route exact path='/' component={Home} /> */}
            <Route path='/counter' component={Counter} />
            <AuthorizeRoute path='/fetch-data' component={FetchData} />
            <AuthorizeRoute path='/users' component={UsersRouter} />
            <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
          </Layout>
        }
      </>
		);
	}
}

const mapStateToProps = state => {
  return state
}
const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(actions, dispatch)
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(App)));
