import React from 'react';
import fetchAxios from '../../fetch/axios';
import '../../style/chat.less';
import moment from 'moment'
// import BotAvt from '../../images/chatbot_avatar.png';
import { Input, Avatar, Tag, Select, Button, message, Tooltip } from 'antd';
import { RedoOutlined, UnorderedListOutlined, LineOutlined, CopyOutlined, PaperClipOutlined } from '@ant-design/icons';
import ReactMarkdown from 'react-markdown';
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import FilePreview from ".././components/filePreview";
// import ChatHelper from '.././components/chatHelper';
import QAModule from './qaModoule';
// import SendImg from "../../images/icon_send.png";
import PromptsLib from './promptsLib';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { coldarkDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import authService from '../../components/api-authorization/AuthorizeService';
import Security from '../components/security';
import axios from 'axios';
import UploadChat from '../components/uploadChat';
import UploadList from '../components/uploadList';
import SpeechComponent from '../components/SpeechComponent.js'
// import ic_upload from '../../images/icon/ic_upload.svg';
import { encode } from 'gpt-tokenizer';


const { TextArea } = Input;

class Chat extends React.Component {
    state = {
        pId: "",
        chatMsg: [],
        askMsg: "",
        isGenerating: false,
        tempAsk: "",
        tempAnswer: "",
        tempAnswerArr: [],
        chatObj: {
            subjectGUID: "",
        },
        newId: "",
        isNew: false,
        subId: "",
        isAddingMsg: false,
        isTyping: false,
        pSetting: {},
        defaultQuestion: [],

        showMoreQuestion: false, //是否显示更多问题
        isGettingDefaultQuestion: false, //是否正在获取默认问题
        imgLoadedArr: [], //图片是否加载完成
        showLib: false, //是否显示提示库

        selectedPrompt: {}, //选中的提示

        language: "", //语言
        tone: "", //语气
        writeStyle: "", //写作风格
        languageOpt: [], //语言选项
        toneOpt: [], //语气选项
        writeStyleOpt: [], //写作风格选项

        textLanguage: "", //显示语言

        canScroll: true, // 输出时是否运行滚动到底部

        uploadFileList: [], // 聊天文件列表
        tempfileList: [], // 生成回答时的文件列表

        tokens: 0, // 聊天消耗token
        fileTokens: 0, // 文件消耗token
        questionTokens: 0, // 问题消耗token
        totalTokens: 0, // 总消耗token
        showTokens: 0, // 显示消耗token
        tokensMax: 128000, // 聊天总容量
    }
    childRef = React.createRef();
    uploadRef = React.createRef();
    componentDidMount() {
        // console.log(this.props)
        // this.getPromptsConfig();

        let scrollEle = document.querySelector('.cp_chat_main');
        scrollEle.addEventListener('mousewheel', this.scrollListen.bind(this))
    }
    static getDerivedStateFromProps(props, state) {
        if (props.chatObj.subjectGUID !== state.chatObj.subjectGUID) {
            return {
            }
        }
        if (props.pId !== state.pId) {
            return {
            }
        }
        // 语言变化
        if (props.i18n.language !== state.textLanguage) {
            return {
            }
        }
        return null
    }
    componentDidUpdate() {
        if (this.props.chatObj.subjectGUID !== this.state.chatObj.subjectGUID) {
            this.setState({ chatObj: this.props.chatObj, isGenerating: false }, () => {
                // 清空历史记录
                // console.log(this.state.chatObj.subjectGUID)

                // 清空上传文件列表
                this.uploadRef.current.clearFileList();

                if (this.state.chatObj.subjectGUID) {
                    // 如果不是新建转已有，则先清空，新建转已有则不清空
                    // console.log("status:" ,this.props.chatObj?.isCreated)
                    if (!this.props.chatObj?.isCreated) {
                        this.setState({ chatMsg: [] })
                        //this.getDefaultQuestion(this.state.chatObj.subjectGUID);
                    }
                    this.setState({ newId: "" })
                    // 有id获取历史记录 默认问题
                    this.getHistory()
                } else {
                    // 无id生成id
                    let newId = this.getGuid();
                    this.setState({
                        chatMsg: [],
                        newId,
                        tokens: 0,
                    }, () => {
                        this.updateShowTokens();
                    });
                    //this.getDefaultQuestion(newId);
                }
            })
        }
        if (this.props.pId !== this.state.pId) {
            this.setState({ pId: this.props.pId }, () => {
                this.getProjectConfig();
            });
        }
        if (this.props.reducer.chatInfo.promptsLibOpen !== this.state.showLib) {
            this.setState({ showLib: this.props.reducer.chatInfo.promptsLibOpen }, () => {
                // 切换回聊天页面时重新添加事件
                if (!this.state.showLib) {
                    let scrollEle = document.querySelector('.cp_chat_main');
                    scrollEle.addEventListener('mousewheel', this.scrollListen.bind(this))
                    // 并滚动到底部
                    setTimeout(() => {
                        this.scrollToBottom();
                    });
                }
            })
        }
        // 语言变化
        if (this.props.i18n.language !== this.state.textLanguage) {
            this.setState({ textLanguage: this.props.i18n.language })
            this.getPromptsConfig();
        }
    }
    componentWillUnmount() {
        let scrollEle = document.querySelector('.cp_chat_main');
        scrollEle?.removeEventListener('mousewheel', this.scrollListen.bind(this));
    }
    // 获取唯一ID
    getGuid() {
        return 'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c === 'x' ? r : ((r & 0x3) | 0x8);
            return v.toString(16);
        });
    }
    // 检查是否是移动端
    checkMobile() {
        let userAgentInfo = navigator.userAgent;
        let Agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod", "Mobile"];
        let flag = false;
        for (let v = 0; v < Agents.length; v++) {
            if (userAgentInfo.indexOf(Agents[v]) > 0) {
                flag = true;
                break;
            }
        }
        return flag;
    }
    keyListen(e) {
        if (e.key === "Enter" && !e.shiftKey && !e.ctrlKey && !e.metaKey && !e.altKey && this.checkMobile() === false) {
            this.search();
        }
    }
    scrollListen(e) {
        this.setState({ canScroll: false });
    }
    // 获取项目配置
    getProjectConfig() {
        if (!this.state.pId) return;
        fetchAxios.get(`/Project/Setting/${this.state.pId}`).then((res) => {
            if (res && res.success) {
                if (res.data?.project_name) {
                    document.title = "NovaGPT - " + res.data.project_name;
                }
                this.setState({
                    pSetting: res?.data || {}
                })
            }
        })
    }
    // 获取提示配置
    getPromptsConfig() {
        const { t } = this.props;
        fetchAxios.get(`/api/Prompt/settings`).then((res) => {
            if (res && res.success) {
                let language, tone, writeStyle = "";
                let languageOpt, toneOpt, writeStyleOpt = [];
                res.data?.forEach(element => {
                    element.items.forEach(item => {
                        if (element.category === "Language") {
                            item.label = t('chat.lang.' + item.data_value);
                        }
                        if (element.category === "Tone") {
                            item.label = t('chat.tone.' + item.data_value);
                        }
                        if (element.category === "Writing Style") {
                            item.label = t('chat.style.' + item.data_value);
                        }
                        item.value = item.key_name;
                    })
                    if (element.category === "Language") {
                        languageOpt = element.items;
                        language = element.items[0].value;
                    }
                    if (element.category === "Tone") {
                        toneOpt = element.items;
                        tone = element.items[0].value;
                    }
                    if (element.category === "Writing Style") {
                        writeStyleOpt = element.items;
                        writeStyle = element.items[0].value;
                    }
                });
                this.setState({
                    languageOpt,
                    toneOpt,
                    writeStyleOpt,
                    language,
                    tone,
                    writeStyle
                })
            }
        })
    }
    // 获取历史
    getHistory() {
        // 查询历史记录
        let sguid = this.state.chatObj.subjectGUID;
        fetchAxios.get(`/api/chat/conversations/${sguid}`).then((res) => {
            if (res && res.success) {
                // console.log(res.data);
                // 会话已经改变时抛出
                if (sguid !== this.state.chatObj.subjectGUID) return;
                this.setState({
                    chatMsg: res.data,
                    tokens: res.tokens || 0,
                }, () => {
                    this.updateShowTokens();
                })
                this.scrollToBottom();
            } else {
                this.setState({ chatMsg: [] })
            }
        })
    };
    // 获取默认问题
    getDefaultQuestion(sId, type) {
        if (this.state.isGettingDefaultQuestion) return;
        this.setState({ isGettingDefaultQuestion: true })
        // type=all 全部问题 type=refresh 刷新 type=default 默认
        // refresh时，额外传入最后一条问题的id
        let str = type === "refresh" ? `?type=refresh&id=${this.state.defaultQuestion[this.state.defaultQuestion.length - 1]?.sort_question}` :
            type === "all" ? "?type=all" : "?type=default";
        // this.setState({defaultQuestion: []})
        fetchAxios.get(`/Project/${this.state.pId}/SuggestQuestions/${sId}${str}`).then((res) => {
            if (res && res.success && res.data) {
                this.setState({
                    defaultQuestion: res.data,
                    isGettingDefaultQuestion: false
                });
                if (type === "all") {
                    this.setState({
                        showMoreQuestion: true
                    });
                } else {
                    this.setState({
                        showMoreQuestion: false
                    });
                }
            }
        })
    }
    // 收起默认问题
    closeQuestions() {
        let num = this.state.pSetting?.firstQuestionShowNum || 5;
        this.setState({
            defaultQuestion: this.state.defaultQuestion.slice(0, num),
            showMoreQuestion: false
        })
    }
    getCookie(name) {
        let arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
        arr = document.cookie.match(reg)
        return arr ? unescape(arr[2]) : null;
    }
    resetChat() {
        this.setState({
            tempAnswerArr: [],
            tempAnswer: '',
            tempfileList: [],
            isGenerating: false,
        })
        // if (this.state.isNew) {
        //   this.props?.creating(this.state.subId);
        //   this.setState({isNew: false, subId: ""});
        // }
    }
    // 查询问题
    async search(askStr, questionid, helperId, helpItem) {

        const { used } = this.props.reducer;
        if ((this.state.tokens > this.state.tokensMax) && (used?.type === 'GPT_4_128' || used?.type === 'GPT_4_V')) {
            message.warning("You have reached the maximum number of tokens.");
            return;
        }

        // 关闭提示库
        this.props.setChatInfo({
            promptsLibOpen: false,
        });
        // 传了askStr则为特殊问答，否则为用户输入问题
        if ((!askStr && !this.state.askMsg.trim() && !helperId) || this.state.isGenerating) return;

        // 初始化回复模块
        this.setState({
            isGenerating: true,
            askMsg: "",
            tempAsk: askStr || this.state.askMsg.trim(),
            tempAnswer: "请稍等!努力思考中...",
            tempfileList: this.state.uploadFileList,
            canScroll: true, // 允许内容过长时自动滚动到底部
            tokens: this.state.tokens + this.state.questionTokens + this.state.fileTokens,
            questionTokens: 0,
        });

        // 滚动到底部，综合直接提问和在提示词面板提问后方案
        setTimeout(() => {
            this.scrollToBottom();
        });

        let subId = this.state.chatObj.subjectGUID || this.state.newId;
        this.setState({ isNew: subId === this.state.newId, subId });
        let searchObj = {
            "role": "user",
            "content": askStr || this.state.askMsg.trim(),
            //"questionid": questionid || "", // 选中默认问题时的id
            "engine": this.props.reducer.used?.type || "GPT", // 引擎类型
            // "subjectid": subId,
            "templaterid": this.state.selectedPrompt.id,
            "language": this.state.language,
            "tone": this.state.tone,
            "writingStyle": this.state.writeStyle,
            "fileContents": this.state.uploadFileList.map((item) => {
                if (item.status === "done") {
                    return {
                        fileConent: item.response?.data?.fileConent,
                        fileName: item.response?.data?.fileName,
                        fileType: item.type,
                        fileSize: item.response?.data?.fileSize,
                        filePath: item.response?.data?.filePath || "",
                        tokens: item.response?.data?.tokens,
                    };
                }
            }),
        }

        if (searchObj.engine !== 'GPT') {
            let imgList = document.querySelectorAll(".cp_chat_main img");
            if (imgList.length > 0) {
                // 取最后一张
                let lastImg = imgList[imgList.length - 1];
                let imgObj = {
                    "fileConent": "",
                    "fileName": "donotshow",
                    "fileType": "catchImg",
                    "fileSize": 0,
                    "filePath": lastImg.src,
                    "tokens": 0,
                }
                searchObj.fileContents.push(imgObj);
            }
        }


        // 清空上传文件列表
        this.uploadRef.current.clearFileList();

        //助手问答，有askMsg则针对askMsg回复，无则针对上一条问题回复
        if (helperId) {
            searchObj["templaterid"] = helperId;
        }
        if (this.state.selectedPrompt?.id) {
            helperId = this.state.selectedPrompt?.id;
            helpItem = this.state.selectedPrompt;
        }
        // console.log(this.props.responeseType)
        if (this.props.responeseType === 'text') {
            // 普通接口
            fetchAxios.post(`/${this.props.official ? "" : `ask/${this.props.pId}/`}Chat/${subId}`, searchObj).then((res) => {
                if (res) {
                    // 更新用量
                    this.props.reducer?.checkUsage?.();

                    this.setState({ tempAnswer: "" })

                    // 先处理标注内容
                    this.typeWriter(res.answer, 50, () => {
                        // 新建则父组件重新获取列表
                        if (subId === this.state.newId) {
                            this.props.creating?.(subId);
                        }
                        // console.log(res?.suggestquestions)
                        // 将回答输出
                        let resObj = {
                            "question": this.state.tempAsk,
                            "answer": this.state.tempAnswer,
                            "cDate": moment().format(),
                            "suggestQuestions": res?.suggestquestions || [],
                            "source_files": res?.source_files || [],
                            "files": res?.files || [],
                        };

                        this.setState({
                            chatMsg: this.state.chatMsg.concat(resObj),
                            tempAsk: "",
                            tempAnswer: "",
                            tempfileList: [],
                            isGenerating: false,
                            canScroll: true, // 解锁滚动权限
                        }, () => {
                            // console.log(this.state.chatMsg)
                        })
                    });
                }
            })
        } else {
            const token = await authService.getAccessToken();
            // 流式输出
            let tempText = "";
            let _th = this;
            let isDone = false;
            const requestOptions = {
                method: 'POST',
                url: `/${this.props.official ? "" : `Project/${this.props.pId}/`}api/Chat/${subId}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'bearer ' + token,
                },
                responseType: 'stream',
                data: JSON.stringify(searchObj),
                onDownloadProgress: function (progressEvent) {
                    // 切换会话，重置
                    if (subId !== (_th.state.chatObj.subjectGUID || _th.state.newId)) {
                        _th.resetChat();
                        return;
                    }

                    var text = progressEvent.event.currentTarget.responseText;
                    text = text.substring(tempText.length)
                    tempText = progressEvent.event.currentTarget.responseText;
                    // console.log(text)
                    // tempArr.push(text);

                    _th.setState({ isAddingMsg: true }, () => {
                        _th.setState({
                            tempAnswerArr: _th.state.tempAnswerArr.concat(text),
                            isAddingMsg: false,
                        })
                    })
                }
            };
            axios(requestOptions).then((response) => {

                // 更新用量
                this.props.reducer?.checkUsage?.();
                // const reader = response.data.getReader();
                isDone = true;

                // let arrTemp = new Uint8Array([]);
                // const readChunk = () => {
                //   reader.read().then(({ value, done }) => {
                //     // 切换会话，重置
                //     if (subId !== (this.state.chatObj.subjectGUID || this.state.newId)) {
                //       this.resetChat();
                //       return;
                //     }
                //     // console.log("=========================")
                //     // console.log(arrTemp)
                //     // console.log(value, done)
                //     let mergedArray;
                //     if (value) {
                //       mergedArray = new Uint8Array(arrTemp.length + value?.length);
                //       mergedArray.set(arrTemp);
                //       mergedArray.set(value, arrTemp.length)
                //     } else {
                //       mergedArray = new Uint8Array(arrTemp.length);
                //       mergedArray.set(arrTemp);
                //     }
                //     let text = new TextDecoder('utf-8').decode(mergedArray);
                //     if (value) {
                //       text = text?.slice(0, -2);
                //       let brforeCode = new TextEncoder('utf-8').encode(text);
                //       // console.log(brforeCode)
                //       arrTemp = mergedArray.subarray(brforeCode.length);
                //     }
                //     // console.log(text)

                //     this.setState({isAddingMsg: true}, ()=>{
                //       this.setState({
                //         tempAnswerArr: this.state.tempAnswerArr.concat(text),
                //         isAddingMsg: false,
                //       })
                //     })
                //     if (done) {
                //       isDone = true;
                //       return;
                //     }
                //     readChunk();
                //   }).catch((err) => {
                //     console.error(`Error: ${err}`);
                //   });
                // };
                // readChunk();
            })

            let cleared = false;

            // 打印输出
            let typeWriterTimer = setInterval(() => {
                // 切换会话，重置
                if (this.state.subId !== (this.state.chatObj.subjectGUID || this.state.newId)) {
                    this.resetChat();
                    clearInterval(typeWriterTimer)
                    return;
                }
                // 如果不在打印，则开始打印下一段 
                if (!this.state.isTyping) {
                    let tempArr = this.state.tempAnswerArr;
                    let firstItem = tempArr.shift();
                    if (firstItem) {
                        this.setState({
                            tempAnswerArr: tempArr,
                            isTyping: true
                        });
                        // 首次打印时清空回答框
                        if (!cleared) {
                            this.setState({ tempAnswer: "" })
                            cleared = true;
                        }
                        this.typeWriter(firstItem, 50);
                    }
                }
                // 完成打印
                if (this.state.tempAnswerArr.length === 0 && !this.state.isAddingMsg && !this.state.isTyping && isDone) {
                    // 若为新建则父组件重新获取列表
                    if (subId === this.state.newId) {
                        this.props.creating?.(subId);
                    }
                    clearInterval(typeWriterTimer);

                    // 将回答输出
                    let resObj = {
                        "question": this.state.tempAsk,
                        "answer": this.state.tempAnswer,
                        "cDate": moment().format(),
                        "fileList": this.state.tempfileList,
                    };
                    if (helperId) {
                        resObj["chatTemplater_ID"] = helperId;
                        resObj["chatTemplater_Img"] = helpItem.img;
                        resObj["chatTemplater_Title"] = helpItem.title;
                    }
                    // console.log(resObj)
                    this.setState({
                        chatMsg: this.state.chatMsg.concat(resObj),
                    }, () => {
                        this.setState({
                            tokens: this.state.tokens + encode(this.state.tempAnswer).length,
                            tempAsk: "",
                            tempAnswer: "",
                            tempfileList: [],
                            isGenerating: false,
                            canScroll: true, // 解锁滚动权限
                        })
                    })
                }
            }, 100);
        }
    }
    // 打字机效果
    typeWriter(text, speed, callback) {
        // console.log(text)
        let sum = 0;
        const intervalId = setInterval(() => {
            // 切换会话，重置
            if (this.state.subId !== (this.state.chatObj.subjectGUID || this.state.newId)) {
                this.resetChat();
                clearInterval(intervalId);
                this.setState({ isTyping: false })
                return;
            }
            let step = Math.floor(Math.random() * (6)) + 2;
            if (sum < text.length) {
                // 检测图片、视频或音频标签
                const mediaRegex = /<img[\s\S]*?>|<video[\s\S]*?>|<audio[\s\S]*?>/g;
                const match = mediaRegex.exec(text.slice(sum));
                if (match?.index <= step) {
                    // 直接输出图片标签
                    const imgTag = match[0];
                    this.setState({ tempAnswer: this.state.tempAnswer + text.slice(sum, sum + match.index) + imgTag }, () => { console.log(this.state.tempAnswer) });
                    sum = sum + match.index + imgTag.length;
                } else {
                    // 输出文字
                    this.setState({ tempAnswer: this.state.tempAnswer + text.slice(sum, sum + step) },
                        // () => { console.log(this.state.tempAnswer) }
                    )
                    sum += step;
                }
            } else {
                clearInterval(intervalId);
                this.setState({ isTyping: false })
                if (callback) {
                    callback()
                }
            }

            this.scrollToBottom();
        }, speed);
    }
    // 滚动到底部
    scrollToBottom() {
        let { canScroll } = this.state;
        let ele = document.querySelector(".cp_chat_main");
        if (ele && ele.scrollTop >= ele.scrollHeight - ele.clientHeight - 5) {
            canScroll = true;
            this.setState({ canScroll })
        }
        if (!canScroll) return;

        if (ele) ele.scrollTop = ele.scrollHeight;
    }
    viewFile(fileInfo) {
        this.childRef.current.getFile(fileInfo.fileid, fileInfo.filename);
    }
    // 助手问答
    helperSearch(id, helper) {
        // console.log(id);
        this.search("", "", id, helper)
    }
    getForm(fData) {
        // console.log(fData);
        let resObj = {
            "question": "",
            "answer": "",
            "cDate": moment().format(),
            "suggestQuestions": [],
            "source_files": [],
            "searchType": "form",
            "formData": fData,
        };
        this.setState({ chatMsg: this.state.chatMsg.concat(resObj), })
    }
    enterQuestion(e) {
        if (this.state.isGenerating) return;
        this.setState({
            askMsg: e.target.value,
            questionTokens: encode(e.target.value).length,
        }, () => {
            this.updateShowTokens();
        })
    }

    // 设置选中的Prompts
    setPrompts(prompt) {
        // console.log(prompt);
        this.setState({ selectedPrompt: prompt });
    }
    // 选择语言
    changeTopic(e) {
        this.setState({ language: e });
    }
    // 选择语气
    changeTone(e) {
        this.setState({ tone: e });
    }
    // 选择写作风格
    changeWriteStyle(e) {
        this.setState({ writeStyle: e });
    }

    // 格式化数字
    formatNumber(number) {
        if (number < 1000) {
            return number.toString(); // 如果小于1000，直接返回原数字
        } else if (number < 1000000) {
            return (number / 1000).toFixed(1) + 'k'; // 转换成千，并保留1位小数
        } else {
            return (number / 1000000).toFixed(1) + 'M'; // 转换成百万，并保留1位小数
        }
    }
    updateFileList(list) {
        let fileTokens = 0;
        list.forEach((item) => {
            if (item.status === "done") {
                fileTokens += item.tokens || 0;
            }
        })
        // console.log(list)
        this.setState({ uploadFileList: list, fileTokens }, () => {
            this.updateShowTokens();
        })
    }
    updateShowTokens() {
        const { tokens, fileTokens, questionTokens } = this.state;
        this.setState({
            totalTokens: tokens + fileTokens + questionTokens,
            showTokens: this.formatNumber(tokens + fileTokens + questionTokens)
        })
    }

    render() {
        // console.log(this.props)
        const { user, theme, used } = this.props.reducer;
        const { t } = this.props;
        const { tokens, tokensMax, fileTokens, totalTokens, showTokens } = this.state;

        return (
            <div className='cp_chat'>
                {!this.state.showLib ?
                    <div className='cp_chat_main' onTouchStart={() => { this.setState({ canScroll: false }) }}>
                        {this.state.pSetting?.firstMessage &&
                            <div className='chat_item'>
                                {/* 问候语  */}
                                <div className='chat_bot_bg'>
                                    <div className='chat_bot'>
                                        <div className='chat_bot_icon'>
                                            {theme.botIcon}
                                        </div>
                                        <div className='chat_box_bubble'>
                                            <div className='chat_bot_msg pre_line'>
                                                {this.state.pSetting.firstMessage}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* 默认问题 */}
                                {this.props.responeseType === 'text' && this.state.defaultQuestion.length > 0 &&
                                    <div className='chat_bot_bg'>
                                        <div className='chat_bot'>
                                            <div className='chat_bot_icon'>
                                                {theme.botIcon}
                                            </div>
                                            <div className='chat_box_bubble'>
                                                <div className='chat_bot_msg pre_line'>
                                                    <div className='chat_bot_opt'>
                                                        <RedoOutlined onClick={() => { this.getDefaultQuestion(this.state.chatObj.subjectGUID || this.state.newId, 'refresh') }} />
                                                        {this.state.showMoreQuestion ?
                                                            <LineOutlined onClick={() => { this.closeQuestions() }} /> :
                                                            <UnorderedListOutlined onClick={() => { this.getDefaultQuestion(this.state.chatObj.subjectGUID || this.state.newId, 'all') }} />
                                                        }
                                                    </div>
                                                    <div>{this.state.pSetting?.questionShowMessage || "Follow-up questions:"}</div>
                                                    <div className='chat_bot_questions'>
                                                        {this.state.defaultQuestion.map((it, id) => {
                                                            return (
                                                                <div
                                                                    className='chat_bot_ques_item'
                                                                    key={id}
                                                                    onClick={() => {
                                                                        // console.log(it)
                                                                        if (it.text_Type === "Forms" && it.forms) {
                                                                            this.getForm(it.forms)
                                                                        } else {
                                                                            this.search(it.question, it.number)
                                                                        }
                                                                    }}
                                                                    title={it.question}
                                                                >
                                                                    <div>{it.question}</div>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                        {this.state.chatMsg.length === 0 && !this.state.pSetting?.firstMessage && theme.themeName === 'blue_mr' &&
                            <Security {...this.props}></Security>
                        }
                        {/* 问答记录 */}
                        {this.state.chatMsg.map((item, index) => {
                            return (
                                <QAModule
                                    key={index}
                                    data={item}
                                    index={index}
                                    {...this.props}
                                    search={(q, n) => { this.search(q, n) }}
                                    getForm={(fData) => { this.getForm(fData) }}
                                    viewFile={(e) => { this.viewFile(e) }}
                                    scrollToBottom={() => { this.scrollToBottom() }}
                                ></QAModule>
                            )
                        })}
                        {/* 生成回答中 */}
                        {this.state.isGenerating ?
                            <div className='chat_item'>
                                {this.state.tempAsk.trim() !== "" &&
                                    <div className='chat_me_bg'>
                                        <div className='chat_me'>
                                            <Avatar className='chat_me_icon'>{user.shortName}</Avatar>
                                            {/* <div className='chat_me_icon'>C</div> */}
                                            <div className='chat_me_msg'>
                                                <UploadList
                                                    fileList={this.state.tempfileList}
                                                    model='list'
                                                    needRcViewer={false}
                                                    {...this.props}
                                                ></UploadList>
                                                {this.state.tempAsk}
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className='chat_bot_bg'>
                                    <div className='chat_bot'>
                                        <div className='chat_bot_icon'>
                                            {theme.botIcon}
                                        </div>
                                        {/* <div className='chat_bot_msg'>{this.state.tempAnswer}</div> */}
                                        <div className='chat_box_bubble'>
                                            <div className='chat_bot_msg generating'>
                                                <ReactMarkdown
                                                    className='markdown'
                                                    remarkPlugins={[
                                                        // 不替换~为删除线
                                                        [remarkGfm, { singleTilde: false }],
                                                    ]}
                                                    rehypePlugins={[rehypeRaw]}
                                                    components={{
                                                        'em': ({ node, ...props }) => {

                                                            if (props.children?.[0] && typeof props.children?.[0] === 'string' && props.children?.[0].startsWith('^sup')) {
                                                                return <sup>{props.children[0].substring(4)}</sup>
                                                            }
                                                            if (props.children?.[0] && typeof props.children?.[0] === 'string' && props.children?.[0].startsWith('~sub')) {
                                                                return <sub>{props.children[0].substring(4)}</sub>
                                                            }
                                                            return <em {...props} />
                                                        },
                                                        // 'img': ({ node, ...props }) => {
                                                        //   // console.log(node, props)
                                                        //   return <LazyImage {...props} />
                                                        // },
                                                        pre({ children }) {
                                                            const match = /language-(\w+)/.exec(children[0]?.props?.className || '')
                                                            return <div className='chat_code_box'>
                                                                <div className='chat_code_header'>
                                                                    <div>{match?.[1]}</div>
                                                                    <Button
                                                                        onClick={() => {
                                                                            navigator.clipboard.writeText(children[0].props.children[0]);
                                                                            message.success('Copied to clipboard.')
                                                                        }}
                                                                        icon={<CopyOutlined />}
                                                                        size='small'
                                                                        type='text'
                                                                    >Copy code</Button>
                                                                </div>
                                                                {children}
                                                            </div>
                                                        },
                                                        code({ node, inline, className, children, ...props }) {
                                                            const match = /language-(\w+)/.exec(className || '')
                                                            return !inline ? (
                                                                <SyntaxHighlighter
                                                                    {...props}
                                                                    children={String(children).replace(/\n$/, '')}
                                                                    style={coldarkDark}
                                                                    language={match?.[1] || undefined}
                                                                    PreTag="div"
                                                                />
                                                            ) : (
                                                                <code {...props} className={className}>
                                                                    {children}
                                                                </code>
                                                            )
                                                        }
                                                    }}
                                                >
                                                    {this.state.tempAnswer}
                                                </ReactMarkdown>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className='cp_progress'>
                  <div className='cp_pgs_bg'></div>
                  <div className='cp_pgs_bar'>
                    <div className='cp_pgs_ani long'></div>
                    <div className='cp_pgs_ani short'></div>
                    <div></div>
                  </div>
                </div> */}
                            </div>
                            : null}
                    </div> :
                    <PromptsLib selectPrompts={(prompt) => { this.setPrompts(prompt) }} {...this.props}></PromptsLib>
                }
                <div className='cp_chat_ask'>

                    <UploadChat
                        ref={this.uploadRef}
                        {...this.props}
                        guid={this.state.chatObj.subjectGUID || this.state.newId}
                        getlist={(list) => { this.updateFileList(list) }}
                    >

                        <div className='cp_chat_ask_main' onKeyDown={(e) => { this.keyListen(e) }}>
                            {/* {this.props.responeseType === 'stream' &&
                <ChatHelper helperSearch={(id, helper)=>{ this.helperSearch(id, helper) }} t={this.props.t}></ChatHelper>
              } */}

                            <div className='cp_chat_ask_top'>
                                <div className='cp_prompt_top'>
                                    {this.state.selectedPrompt.title ?
                                        <Tag bordered={false} closable onClose={() => { this.setState({ selectedPrompt: {} }) }} color="#565869">
                                            {this.state.selectedPrompt.title}
                                        </Tag>
                                        : null}
                                </div>
                                <div className='cp_prompt_bottom'>
                                    <div className='cp_prompt_s_item'>
                                        <div className='cp_prompt_s_title'>{t('chat.Output In')}</div>
                                        <Select
                                            value={this.state.language}
                                            onChange={(e) => { this.changeTopic(e) }}
                                            bordered={false}
                                            options={this.state.languageOpt}
                                        />
                                    </div>
                                    <div className='cp_prompt_s_item'>
                                        <div className='cp_prompt_s_title'>{t('chat.Tone')}</div>
                                        <Select
                                            value={this.state.tone}
                                            onChange={(e) => { this.changeTone(e) }}
                                            bordered={false}
                                            options={this.state.toneOpt}
                                        />
                                    </div>
                                    <div className='cp_prompt_s_item'>
                                        <div className='cp_prompt_s_title'>{t('chat.Writing Style')}</div>
                                        <Select
                                            value={this.state.writeStyle}
                                            onChange={(e) => { this.changeWriteStyle(e) }}
                                            bordered={false}
                                            options={this.state.writeStyleOpt}
                                        />
                                    </div>
                                    {this.checkMobile() === false &&
                                        <div className='cp_chat_info' title={t('chat.chat info')}>{t('chat.chat info')}</div>
                                    }
                                </div>
                            </div>
                           {/* <SpeechComponent></SpeechComponent>*/}
                            <UploadList
                                fileList={this.state.uploadFileList}
                                onFileDelete={(e) => { this.uploadRef.current.deleteFile(e) }}
                                needRcViewer={true}
                                {...this.props}
                            ></UploadList>
                            <div className='cp_chat_ask_bottom'>
                                {/* <SearchOutlined/> */}
                                {(used?.type === 'GPT_4_128' || used?.type === 'GPT_4_V') &&
                                    <div className='cp_upload_img' onClick={(e) => { this.uploadRef.current.clickToUpload(e) }}>
                                        <PaperClipOutlined />
                                    </div>
                                }
                                <TextArea
                                    value={this.state.askMsg}
                                    onChange={(e) => this.enterQuestion(e)}
                                    placeholder={this.state.selectedPrompt?.promptHint || t('chat.Ask me')}
                                    autoSize={{
                                        minRows: 1,
                                        maxRows: 5,
                                    }}
                                />
                                
                                <div className={(used?.type === 'GPT_4_128' || used?.type === 'GPT_4_V') ? 'cp_chat_ask_flot gpt128k' : 'cp_chat_ask_flot'}>
                                    {(used?.type !== 'GPT_4_128' && used?.type !== 'GPT_4_V') &&
                                        <div className='cp_chat_ask_counter'><span>{this.state.askMsg.length}</span>/1000</div>
                                    }
                                    <Tooltip title={totalTokens > tokensMax && (used?.type === 'GPT_4_128' || used?.type === 'GPT_4_V') ? t("抱歉，当前会话已到达容量上限，请新建会话再尝试。") : ""} color={'#FF4D4F'}>
                                        <div className={totalTokens > tokensMax && (used?.type === 'GPT_4_128' || used?.type === 'GPT_4_V') ? 'cp_chat_ask_enter disabled' : 'cp_chat_ask_enter'} onClick={() => this.search()}>
                                            {theme.sendIcon}
                                        </div>
                                    </Tooltip>
                                    {(used?.type === 'GPT_4_128' || used?.type === 'GPT_4_V') &&
                                        <Tooltip title="" color={'#FF4D4F'}>
                                            <div className={totalTokens > tokensMax ? 'cp_chat_usage disabled' : 'cp_chat_usage'} title={`${totalTokens} / ${tokensMax}`}>消耗: <span>{showTokens}</span> / {this.formatNumber(tokensMax)}</div>
                                        </Tooltip>
                                    }
                                </div>
                            </div>
                        </div>
                    </UploadChat>
                </div>
                <FilePreview ref={this.childRef}></FilePreview>
            </div>
        )
    }
}

export default Chat