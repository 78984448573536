import React, { useState } from 'react';
import * as sdk from 'microsoft-cognitiveservices-speech-sdk';

import { Button } from 'antd';
import { AudioOutlined } from '@ant-design/icons';
import '../../style/speechComponent.less';
// �������Ķ�����Կ�������ǻ��������е�ֵ������Ҫ����Ŀ����Ӧ����������
const subscriptionKey = "1f5c983a11b94c4e9ed6762d2d9f32df";
const serviceRegion = "eastasia";

const speechConfig = sdk.SpeechConfig.fromSubscription(subscriptionKey, serviceRegion);
speechConfig.speechRecognitionLanguage = "zh-CN";


const SpeechComponent = () => {
    const [recognizedText, setRecognizedText] = useState('');
    const [recognizer, setRecognizer] = useState(null);

    const startRecognition = () => {
        setRecognizedText('');
        // ����һ������ʶ�������ʶ����ʵ��
        const audioConfig = sdk.AudioConfig.fromDefaultMicrophoneInput();
        const newRecognizer = new sdk.SpeechRecognizer(speechConfig, audioConfig);
        setRecognizer(newRecognizer);
        

        // ��ʼ����ʶ��
        newRecognizer.startContinuousRecognitionAsync();

        // ���ý���¼���������
        newRecognizer.recognized = (s, e) => {
            if (e.result.reason === sdk.ResultReason.RecognizedSpeech) {
                setRecognizedText(prevState => prevState + e.result.text);           
            
            }
        };

        // ����ʶ������¼���������
        newRecognizer.canceled = (s, e) => {
            // �ر�����ʶ����
            newRecognizer.close();
            setRecognizer(null);
        };
    };

     

    const stopRecognition = () => {
        if (recognizer) {
            recognizer.stopContinuousRecognitionAsync(
                () => {
                    recognizer.close();
                    setRecognizer(null);
                    synthesizeSpeech(recognizedText);
                },
                (error) => {
                    console.error(error);
                }
            );
        }
    };

   
    // ����������ڽ��ı�ת��Ϊ����������
    function synthesizeSpeech(text) {
        const synthesizer = new sdk.SpeechSynthesizer(speechConfig);

        synthesizer.speakTextAsync(text,
            result => {
                if (result.reason === sdk.ResultReason.SynthesizingAudioCompleted) {
                    const audioBlob = new Blob([result.audioData], { type: 'audio/wav' });
                    const audioUrl = URL.createObjectURL(audioBlob);
                    const audio = new Audio(audioUrl);
                    // ������Ƶ���Ž������¼�
                    audio.onended = () => {
                        // ȷ����Ƶ������Ϻ�����
                        URL.revokeObjectURL(audioUrl);
                    };
                    audio.play();
                    
                }
                synthesizer.close();
            },
            error => {
                console.error(error);
                synthesizer.close();
            });
    }

    return (
        <div>
            <Button
                onMouseDown={startRecognition} // ������ʼʶ��
                onMouseUp={stopRecognition} // �ɿ�ֹͣʶ�𲢷�������
                icon={<AudioOutlined />}
                className="recognition-button"
            >
                Press and Hold
            </Button>
            <p>Result: {recognizedText}</p>
        </div>
    );
};

export default SpeechComponent;

