import translation_en from './en.json';
import translation_zh from './zh.json';
import translation_ja from './ja.json';

const resources = {
  en: {
    translation: translation_en,
  },
  zh: {
    translation: translation_zh,
  },
  ja: {
    translation: translation_ja,
  },
};

export default resources;