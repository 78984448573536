import React from 'react'
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Layout, theme } from 'antd';
// import icon from '../images/icon.js';
// import fetch from '../fetch/axios';
// import logo from '../../images/logo.svg';
import '../../style/nav.less';
import HeaderUsage from './usage'
import ChatList from '../chat/chatList'

const { Sider } = Layout;

class NavigationBar extends React.Component {
  state = {
    pathname: "",
    selectedKeys: ['projects', 'dashboard'],
    role: "",
    lastOpen: ['square', 'dashboard'],
    search: "",
  }
  componentDidMount() {
    // console.log(this.props);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.location.pathname !== state.pathname) {
      return {
      }
    }
    if (props.user.role !== state.role) {
      return {
      }
    }
    return null
  }
  componentDidUpdate() {
    if (this.props.location.pathname !== this.state.pathname) {
      this.setState({ pathname: this.props.location.pathname }, () => {
        if (this.state.pathname === '/') {
          this.props.history.push('/chat-official')
        } else {
          this.listenRoute(this.state.pathname);
        }
      })
    }
    if (this.props.user.role !== this.state.role) {
      this.setState({ role: this.props.user.role })
    }
  }

  listenRoute(path) {
    // console.log(path)
    if (path.includes('/create')) {
      this.setState({selectedKeys: ['create', 'dashboard']})
    } else if (path.includes('/projects/list')) {
      this.setState({selectedKeys: ['projects', 'dashboard']})
    } else if (path.includes('/chat-official')) {
      this.setState({selectedKeys: ['chat']})
    } else if (path.includes('/square')) {
      this.setState({selectedKeys: ['square']})
    } else if (path.includes('/s-create')) {
      this.setState({selectedKeys: ['s-create', 'square']})
    } else if (path.includes('/my-square')) {
      this.setState({selectedKeys: ['my-square', 'square']})
    } else if (path.includes('/welcome')) {
      this.setState({selectedKeys: ['welcome', 'support']})
    } else if (path.includes('/faq')) {
      this.setState({selectedKeys: ['faq', 'support']})
    } else if (path.includes('/system/ai')) {
      this.setState({selectedKeys: ['AISetting', 'settings']})
    } else if (path.includes('/system/role')) {
      this.setState({selectedKeys: ['RoleSetting', 'settings']})
    } else if (path.includes('/system/user')) {
      this.setState({selectedKeys: ['UserSetting', 'settings']})
    } else {
      this.setState({selectedKeys: ['dashboard']})
    }
  }

  changeNavPath(e) {
    // console.log(e);
    // console.log(this.props.history);
    if (e.key === 'create') {
      this.props.history.push('/create');
    } else if (e.key === 'projects') {
      this.props.history.push('/projects/list');
    } else if (e.key === 'chat') {
      this.props.history.push('/chat-official');
    } else if (e.key === "faq") {
      this.props.history.push('/faq');
    } else if (e.key === "contact") {
      window.open("https://www.novagpt.ai/contact-us")
    } else if (e.key === "square") {
      this.props.history.push('/square');
    } else if (e.key === "s-create") {
      this.props.history.push('/s-create');
    } else if (e.key === "my-square") {
      this.props.history.push('/my-square');
    } else if (e.key === "welcome") {
      this.props.history.push('/welcome');
    } else if (e.key === "AISetting") {
      this.props.history.push('/system/ai');
    } else if (e.key === "RoleSetting") {
      this.props.history.push('/system/role');
    } else if (e.key === "UserSetting") {
      this.props.history.push('/system/user');
    }
    if (this.props.smallScreen) {
      this.props.setNavData({collapsed: true});
    }
  }

  changePath() {
    this.props.setChatInfo({
      chatObj: {},
    });
    this.props.history.push('/chat-official');
  }
  closeNav(e) {
    if (e.target?.className === "navigation" && this.props.smallScreen) {
      this.props.setNavData({collapsed: true});
    }
  }
  clickSubMenu(arr) {
    let lastArr = this.state.lastOpen;
    // console.log(lastArr)
    // 点开了广场页面
    if (arr.includes('square') && !lastArr.includes('square')) {
      this.props.history.push('/square')
    }
    this.setState({lastOpen: arr})
  }

  changeSearch(e) {
    this.setState({search: e.target.value})
  }

  enterToSearch(e) {
    if (e.keyCode === 13) {
      // fetch
    }
  }

  render() {
    const { collapsed, smallScreen, moveToOpen, t } = this.props;
    const { theme } = this.props.reducer;
    return (
      <div className="navigation" open={!collapsed} onClick={(e)=>{ this.closeNav(e); }}>
        <Sider
          trigger={null}
          breakpoint='xl'
          onBreakpoint={(broken) => {
            this.props.setNavData({collapsed: broken, smallScreen: broken});
          }}
          width={'15.625rem'}
          collapsedWidth={0}
          collapsible
          collapsed={collapsed}
          onMouseEnter={()=>{
            if (smallScreen) return;
            this.props.setNavData({collapsed: false})
          }}
          onMouseLeave={(e)=>{
            if (smallScreen || !moveToOpen) return;
            this.props.setNavData({collapsed: true})
          }}
        >
          {theme.navIcon ?
            <div className={collapsed ? 'logo_box collapsed':'logo_box'}>
              <div className='icon_box' onClick={()=>{ this.changePath() }}>
                <img src={theme.navIcon}  className='logo' alt=''></img>
              </div>
              {/* <CloseOutlined className='nav_close'/> */}
              {React.createElement(collapsed || moveToOpen ? MenuUnfoldOutlined : MenuFoldOutlined, {
                className: 'nav_close',
                onClick: () => {
                  if (!collapsed && moveToOpen) {
                    this.props.setNavData({collapsed: false, moveToOpen: false})
                    return
                  }
                  this.props.setNavData({collapsed: !collapsed, moveToOpen: !collapsed})
                },
              })}
            </div>
          :null}
          <ChatList {...this.props} official={true}></ChatList>
          <HeaderUsage {...this.props} showWidth={1024}></HeaderUsage>
          {/*<div className='nav_power'>
            {t("nav.Power 1")} <a href="https://www.novagpt.ai/" target="_blank" rel="noopener noreferrer">{t("nav.NovaTech")}</a> {t("nav.Power 2")}
          </div>*/}
        </Sider>
      </div>
    )
  }
}

export default NavigationBar;