import React from 'react';
import fetch from '../../fetch/axios';
import '../../style/chatList.less';
import { Button, Input } from 'antd';
import { PlusCircleOutlined, MessageOutlined, DeleteOutlined, SearchOutlined, EditOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
// import Chat from './chat';
import moment from 'moment';

class ChatList extends React.Component {
  state = {
    pId: '',
    chatIndex: -1,
    listData: {},
    chatObj: {},
    isDeleting: false,
    isRenaming: false,
    editId: '',
    editObj: {},
    dateArr: ["Today", "Yesterday", "Last 7 Days", "Last 30 Days"]
  }
  componentDidMount() {
    let params = new URLSearchParams(window.location.hash.split('?')[1]);
    let pId = params.get('pId');
    this.setState({pId}, ()=>{
      this.getChatList(pId);
    });
  }
  componentDidUpdate() {
    if (this.state.chatIndex === -1 && this.state.chatObj.subjectGUID === undefined && this.props.reducer.chatInfo.chatObj.subjectGUID !== undefined) {
      this.setState({
        chatObj: this.props.reducer.chatInfo.chatObj,
        chatIndex: this.props.reducer.chatInfo.chatObj.subjectGUID
      })
      this.getChatList(this.state.pId);
    }
  }
  // 获取聊天列表
  getChatList(pId, callback, title) {
    if (pId === "create") {
      pId = this.state.pId;
    }
    // this.setState({listData: {}})
    fetch.get(`/${this.props.official ? 'api/Chat/' : `Project/${pId}/`}conversations${title? `?title=${title}`:""}`).then((res)=>{
      if (res && res.success) {
        let listData = this.setChatList(res.data);
        let chaObj = {
          projectType: res.projectType, // 'File' 'Hub'
          responeseType: res.responeseType  // 'stream' 'text'
        };
        this.props.setChatInfo(chaObj);
        this.setState({
          listData,
        });
        if (callback) {
          return callback();
        }
      }
    })
  }
    changeChat(citem) {
        if ('report' == citem) {
            //如果是报告撰写
            console.log("报告撰写")
            this.props.history.push(`/report-write`);

            // 没有citem
            this.setState({
                chatIndex: citem.subjectGUID || -1,
                chatObj: citem,
            });
            // 切换
            this.props.setChatInfo({
                chatObj: citem, // 更新聊天信息
                promptsLibOpen: false, // 关闭常用语库
            });

        } else {
            // 如果路由不为chat
            if (this.props.history.location.pathname !== '/chat-official') {
                this.props.history.push(`/chat-official`);
            }
            // 没有citem
            this.setState({
                chatIndex: citem.subjectGUID || -1,
                chatObj: citem,
            });
            // 切换
            this.props.setChatInfo({
                chatObj: citem, // 更新聊天信息
                promptsLibOpen: false, // 关闭常用语库
            });
        }
    
  }

  changeSearch(e) {
    this.setState({
      search: e.target.value
    })
  }
  enterToSearch(e) {
    if (e.keyCode === 13) {
      this.getChatList(this.state.pId, null, this.state.search?.trim());
    }
  }

  sureToOpt() {
    let { editObj, editId } = this.state;
    console.log(editObj, editId)
    if ( editId === '') return;
    if (editObj.type === 'reName') {
      // 重命名方法
      this.changeTitle(editId, editObj);
    } else if (editObj.type === 'delete') {
      // 删除方法
      this.deleteChat(editId, editObj.key, editObj.index);
    }
  }
  deleteChat(id, key, index) {
    // console.log(item)
    if (this.state.isDeleting) return;
    this.setState({isDeleting: true})

    fetch.post(`/api/chat/conversations/Delete/${id}`).then((res)=>{
      if (res && res.success) {
        let listData = this.state.listData;
        listData[key]?.splice(index, 1);
        this.setState({
          isDeleting: false,
          listData
        })
        // 切换回创建新主题
        this.changeChat({});
      }
    })
  }
  changeTitleValue(e) {
    let editObj = this.state.editObj;
    editObj.value = e.target.value;
    this.setState({
      editObj
    })
  }
  changeTitle(id, obj) {
    if (this.state.isRenaming) return;
    this.setState({isRenaming: true})

    fetch.post(`/api/chat/conversations/rename/${id}`, {title: obj.value}).then((res)=>{
      if (res && res.success) {
        let listData = this.state.listData;
        listData[obj.key][obj.index].question = obj.value;
        this.setState({
          isRenaming: false,
          listData,
          editId: '',
          editObj: {}
        })
      }
    })
  }

  setChatList(listArr) {
    const today = moment().startOf('day');
    const yesterday = moment().subtract(1, 'days').startOf('day');
    const last7Days = moment().subtract(6, 'days').startOf('day');
    const last30Days = moment().subtract(29, 'days').startOf('day');

    listArr.sort((a, b) => {
      const dateA = new Date(a.cDate);
      const dateB = new Date(b.cDate);
      return dateB - dateA;
    });

    let listObj = {};

    listArr.forEach(record => {
      const recordDate = moment(record.cDate);
    
      if (recordDate.isSame(today, 'day')) {
        listObj['Today']?.push(record) || (listObj['Today'] = [record]);
      } else if (recordDate.isSame(yesterday, 'day')) {
        listObj['Yesterday']?.push(record) || (listObj['Yesterday'] = [record]);
      } else if (recordDate.isAfter(last7Days)) {
        listObj['Last 7 Days']?.push(record) || (listObj['Last 7 Days'] = [record]);
      } else if (recordDate.isAfter(last30Days)) {
        listObj['Last 30 Days']?.push(record) || (listObj['Last 30 Days'] = [record]);
      } else {
        const yearMonth = recordDate.format('YYYY-MM');
        if (!listObj[yearMonth]) {
          listObj[yearMonth] = [];
        }
        listObj[yearMonth].push(record);
      }
    });

    return listObj;
  }

  render() {
    const { t, setNavData, smallScreen } = this.props;
    return (
      <div className='chat_list'>
        <div className='am_chat'>
          <div className={`am_chat_list`}>
            <div className='am_chat_list_header'>
              <Button icon={<PlusCircleOutlined />} type='primary' className='am_chat_add_btn' onClick={()=>{ if (smallScreen) setNavData({ collapsed: true }); this.changeChat({}) }} >{t('chat.New')}</Button>
             </div>
             <div className='am_chat_list_header'>
              <Button icon={<PlusCircleOutlined />} type='primary' className='am_chat_add_btn' onClick={() => { if (smallScreen) setNavData({ collapsed: true }); this.changeChat('report') }} >{t('chat.Report')}</Button>
            </div>
            <div className='search_bar'>
              <Input
                value={this.state.search}
                size='large'
                className='search'
                placeholder={t('chat.Search')}
                prefix={<SearchOutlined />}
                onChange={(e)=>{ this.changeSearch(e) }}
                onKeyDown={(e)=>{ this.enterToSearch(e) }}
              />
            </div>
            <div className='am_chat_list_bottom'>
              {Object.keys(this.state.listData).map((key)=>{
                return (
                  <div className='am_chat_list_block' key={key}>
                    <div className='am_chat_list_title'>{this.state.dateArr.includes(key)? t("chat." + key): key}</div>
                    {this.state.listData[key].map((item, index)=>{
                      return (
                        <div
                          className={this.state.chatIndex === item.subjectGUID ? "chat_item chat_choosed":"chat_item"}
                          onClick={()=>{ this.changeChat(item) }}
                          key={index}
                        >
                          <MessageOutlined className='chat_icon'/>
                          {this.state.editId === item.subjectGUID && this.state.editObj.type === "reName" ?
                            <Input
                              value={this.state.editObj.value}
                              size='small'
                              // className='chat_title'
                              onChange={(e) => { this.changeTitleValue(e) }}
                            /> :
                            <div
                              className='chat_title'
                            >{item.question}</div>
                          }
                          {this.state.chatIndex === item.subjectGUID ?
                            this.state.editId !== item.subjectGUID ?
                            <>
                              <EditOutlined className="edit" onClick={(e)=>{
                                e.stopPropagation();
                                this.setState({
                                  editId: item.subjectGUID,
                                  editObj: {
                                    type: "reName",
                                    value: item.question,
                                    key,
                                    index
                                  }
                                })
                              }}/>
                              <DeleteOutlined className="delete" onClick={(e)=>{
                                e.stopPropagation();
                                this.setState({
                                  editId: item.subjectGUID,
                                  editObj: {
                                    type: "delete",
                                    value: "",
                                    key,
                                    index
                                  }
                                })
                              }}/>
                            </>:
                            <>
                              <CheckOutlined className="confirm" onClick={(e)=>{
                                e.stopPropagation();
                                this.sureToOpt();
                              }}/>
                              <CloseOutlined className="cancel" onClick={(e)=>{
                                e.stopPropagation();
                                this.setState({
                                  editId: '',
                                  editObj: {}
                                })
                              }}/>
                            </>
                          :null}
                        </div>
                      )
                    })}
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ChatList